// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

$tooltip-background-color: $dark-gray5 !default;
$tooltip-text-color: $light-gray5 !default;

$dark-tooltip-background-color: $light-gray3 !default;
$dark-tooltip-text-color: $dark-gray5 !default;

$tooltip-padding-vertical: $pt-grid-size !default;
$tooltip-padding-horizontal: 1.2 * $pt-grid-size !default;
