// Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "../../common/variables";
@import "./common";

/*
Tags

Markup:
<span class="#{$ns}-tag {{.modifier}}">125</span>
<span class="#{$ns}-tag {{.modifier}}">Done</span>
<span class="#{$ns}-tag {{.modifier}}">
  Tracking
  <button class="#{$ns}-tag-remove"></button>
</span>
<span class="#{$ns}-tag {{.modifier}}">
  Crushed
  <button class="#{$ns}-tag-remove"></button>
</span>
<span class="#{$ns}-tag {{.modifier}}">
  A rather long string of text that wraps to multiple lines
  demonstrates the position of the remove button.
  <button class="#{$ns}-tag-remove"></button>
</span>

.#{$ns}-large - Large
.#{$ns}-minimal - Minimal appearance
.#{$ns}-round - Rounded corners, ideal for badges
.#{$ns}-interactive - Hover and active effects
.#{$ns}-intent-primary - Primary intent
.#{$ns}-intent-success - Success intent
.#{$ns}-intent-warning - Warning intent
.#{$ns}-intent-danger  - Danger intent

Styleguide tag
*/

.#{$ns}-tag {
  @include pt-tag();

  &.#{$ns}-large,
  .#{$ns}-large & {
    @include pt-tag-large();
  }

  @each $intent, $color in $pt-intent-colors {
    &.#{$ns}-intent-#{$intent} {
      @include pt-tag-intent($color);
    }
  }

  &.#{$ns}-fill {
    display: flex;
    width: 100%;
  }

  &.#{$ns}-minimal {
    @include pt-tag-minimal();

    @each $intent, $color in $pt-intent-colors {
      &.#{$ns}-intent-#{$intent} {
        @include pt-tag-minimal-intent(
          $color,
          map-get($pt-intent-text-colors, $intent),
          map-get($pt-dark-intent-text-colors, $intent)
        );
      }
    }
  }
}

.#{$ns}-tag-remove {
  @include pt-tag-remove();
}
