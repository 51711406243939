// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "./common";

.#{$ns}-datetimepicker {
  background-color: $white;
  border-radius: $pt-border-radius;
  padding: $pt-grid-size;
  text-align: center;

  .#{$ns}-dark & {
    background: $dark-gray4;

    .#{$ns}-datepicker {
      border-bottom: 1px solid $pt-dark-divider-black;
    }
  }

  .#{$ns}-datepicker {
    border-bottom: 1px solid $pt-divider-black;
    padding: 0 0 $pt-grid-size;
  }

  .#{$ns}-timepicker {
    margin-top: $pt-grid-size;
  }
}
