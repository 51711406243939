@import './blueprint.css';

body {
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@media (max-width: 800px) {
  #root {
    max-width: 100vw;
  }
}

/* this is a HACK so that the date picker can use the modifier prop
   with disabled days in the heatmap-viewer/image-panel component */
/* upgrading the version of blueprintjs may fix this */
.DayPicker-Day--disabled-day {
  background: none !important;
  cursor: not-allowed !important;
  color: rgba(92, 112, 128, 0.5) !important;
}

/* Another hack to stop Blueprint Select elements from overflowing the page.
   Blueprint solves this by allowing classes to be attached to the popover,
   but this does not work with styled components as the popover is not in the
   scope of the original element */
.bp3-select-popover .bp3-menu {
  max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.bp3-button-group {
  align-items: center;
}

.bp3-popover,
.bp3-popover .bp3-popover-content,
.bp3-menu {
  border-radius: 6px;
}

.bp3-datepicker {
  border-radius: 6px;
}

.bp3-input-group .bp3-input {
  border-radius: var(--button-border-radius);
}

.bp3-numeric-input .bp3-input-group .bp3-input {
  border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
}

.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:first-child {
  border-radius: 0 var(--button-border-radius) 0 0;
}

.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:last-child {
  border-radius: 0 0 var(--button-border-radius) 0;
}

.bp3-button,
.bp3-button.bp3-intent-success,
.bp3-button.bp3-intent-danger,
.bp3-button.bp3-intent-primary {
  border-radius: var(--button-border-radius);
  border: var(--button-border);
  box-shadow: none;
  color: var(--button-text);
  background-image: none;
}

.bp3-button:hover {
  box-shadow: none;
}

.bp3-button .bp3-icon,
.bp3-button .bp3-icon-standard,
.bp3-button .bp3-icon-large {
  color: var(--button-text);
}

.bp3-button.bp3-intent-primary:not(.bp3-minimal) {
  background-color: var(--primary);
  color: var(--button-text-primary);
  background-image: none;
}

.bp3-button.bp3-intent-primary:hover {
  background-color: var(--primary-hover);
  box-shadow: none;
}

.bp3-button.bp3-intent-success:not(.bp3-minimal) {
  background-color: var(--ok);
  color: var(--button-text-success);
  background-image: none;
}

.bp3-button.bp3-intent-success:hover {
  background-color: var(--ok-hover);
  box-shadow: none;
}

.bp3-button.bp3-intent-danger:not(.bp3-minimal) {
  background-color: var(--error);
  color: var(--button-text-error);
  background-image: none;
}

.bp3-button.bp3-intent-danger:hover {
  background-color: var(--error-hover);
  box-shadow: none;
}

.bp3-button:not([class*='bp3-intent-']):not(.bp3-disabled) {
  box-shadow: none;
  color: var(--button-text);
  background: var(--button-background);
}

.bp3-button-group.bp3-minimal
  .bp3-button:not([class*='bp3-intent-']):not(.bp3-disabled) {
  background: none;
}

.bp3-button.bp3-active:not([class*='bp3-intent-']):not(.bp3-disabled) {
  background: var(--button-active-background);
}

.bp3-button:hover:not([class*='bp3-intent-']):not(.bp3-disabled) {
  background: var(--button-hover-background);
}

.bp3-button:not([class*='bp3-intent-']) .bp3-icon {
  color: var(--button-icon-color);
}

.bp3-button.bp3-intent-primary .bp3-icon {
  color: var(--button-text-primary);
}

.bp3-button .bp3-icon.bp3-icon-small-tick {
  background: #fff;
  border-radius: 50%;
}

.bp3-dialog {
  border-radius: var(--blueprint-dialog-border-radius);
  background: var(--blueprint-dialog-background);
}

.bp3-dialog-header {
  border-radius: var(--blueprint-dialog-border-radius)
    var(--blueprint-dialog-border-radius) 0 0;
}

.bp3-tag.bp3-intent-primary {
  background: var(--button-background);
  color: var(--button-text);
}
